import { useState, useCallback, useEffect, useRef } from "react";
import icon_back from "../assets/icon_back@2x.png"
import icon_attachement from "../assets/icon_attachement@2x.png";
import icon_emoji from "../assets/icon_emoji@2x.png";
import icon_link from "../assets/icon_link@2x.png";
import icon_strike from "../assets/icon_strike@2x.png";
import icon_prompt from "../assets/icon_prompt@2x.png";
import icon_bold from "../assets/icon_bold@2x.png";
import icon_thread from "../assets/icon_thread@2x.png";
import icon_emoji_thumbsup from "../assets/icon_emoji_thumbsup@2x.png";
import icon_emoji_heart from "../assets/icon_emoji_heart@2x.png";
import icon_topics from "../assets/icon_topics@2x.png";
import icon_people from "../assets/icon_people@2x.png";
import icon_audio_call from "../assets/icon_audio_call@2x.png";
import icon_video_call from "../assets/icon_video_call@2x.png";
import icon_kebab_menu from "../assets/icon_kebab_menu@2x.png";
import user_image from "../assets/user_image@2x.png"
import user_online from "../assets/user_online@2x.png"
import user_offlne from "../assets/user_offlne@2x.png"
import exit from "../assets/exit.svg"
import trash from "../assets/trash.svg"
import apiService from "../utils/ApiService";
import { formatMessageTimeForChat } from "../utils/TimeFormat";
import { v4 as uuidv4 } from 'uuid';
import TextWithLink from "../components/TextWithLink";
import { app, database } from "../config/firebase";
import {
    getStorage,
    ref,
    getDownloadURL,
    uploadBytes,
    deleteObject,
} from "firebase/storage";
import { toast } from "react-toastify";
import GroupChatPeople from "../components/GroupChatPeople";
import GroupChatTopicList from "../components/GroupChatTopicList";
import GroupChatThread from "../components/GroupChatThread";
import Closeicon from "../assets/Close-icon.svg"
import HomeScreen from "./HomeScreen";

const ChatScreen = ({ data, refreshLeftNavigation }) => {
    const [showPeopleList, setShowPeopleList] = useState(false);
    const [showTopics, setShowTopics] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [textMsg, setTextMsg] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [fileUri, setFileUri] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [showGroupChat, setShowGroupChat] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [groupId, setGroupId] = useState(null);
    const [pastedImage, setPastedImage] = useState(null);
    const [showComponent, setShowComponent] = useState(null)
    const [showTopicDeatils, setShowTopicDetails] = useState(false)
    const [isFetchingMore, setIsFetchingMore] = useState(false);

    let scrollToBottomCalled = false

    const myRef = useRef(null)
    const pastedImageRef = useRef(null);

    useEffect(() => {
        setTextMsg('');
        setMessages([]);
        apiService.setupWebSocketChatList(async () => {
            await fetchGetHistroy();
        });
    }, [data]);

    const fetchGetHistroy = async () => {
        getHistroy(window.product_userId, "")
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getHistroy = async (userId, messageId) => {
        const getmessageobj = {
            type: "get_conversation",
            ...(messageId ? { message_id: messageId } : {})
        };

        if (data['group_name']) {
            setGroupId(data.id);
            getmessageobj.receiver = 0;
            getmessageobj.sender = 0;
            getmessageobj.group_id = data.id;
            getmessageobj.agency_id = 0;
            getmessageobj.topic_id = 0;
            getmessageobj.thread_id = 0;
        } else {
            getmessageobj.receiver = data.id;
            getmessageobj.sender = window.product_userId;
            getmessageobj.agency_id = window.agencyId;
        }

        apiService.sendMessageListRequest(JSON.stringify(getmessageobj));
        setIsLoading(false);
    };

    // Fetch data from API and store in cache. This is called when we have a new message
    async function fetchData() {
        try {
            // Set up callback for received messages
            apiService.setChatUselistCallback((temmessage) => {
                // Update state with the new message
                if (temmessage?.messages) {
                    const newMessagesArray = temmessage.messages
                        //.filter((element) => element.content !== '' && !messages.some((msg) => msg.message_uuid === messageData.message_uuid)) // Filter out messages with null content
                        .filter((element) => {
                            // Check if both topic_id and thread_id are null or 0
                            return (element.topic_id === null || element.topic_id === 0) &&
                                (element.thread_id === null || element.thread_id === 0) &&
                                element.content !== '' &&
                                !messages.some((msg) => msg.message_uuid === element.message_uuid);
                        })
                        .map((element) => ({
                            _id: element.id,
                            text: element.content,
                            lastMessageTime: formatMessageTimeForChat(element.created_at),
                            userId: element.user_id,
                            type: element.sender_id === window.product_userId ? 'outgoing' : 'incoming',
                            read: element.isread,
                            fileUri: element.attachement_url,
                            fileType: element.attachment_type,
                            fileName: element.attachment_name,
                            userImage: data.profileimage,
                            message_uuid: element.message_uuid,
                            reply_message: element.reply_message,
                            reply_message_id: element.reply_message_id,
                            reply_message_type: element.reply_message_type,
                            reply_message_name: element.reply_message_name,
                            topic_id: element.topic_id,
                            thread_id: element.thread_id,
                            total_thread_count: element.total_thread_count,
                            thread_id_no: element.thread_id_no,
                            userName: element.first_name + (element.last_name ? " " + element.last_name : ""),
                        }));
                    // Update state by merging the new messages array with the existing messages
                    setMessages((prevMessages) => [...prevMessages, ...newMessagesArray]);
                    setIsLoading(false);
                    if (!scrollToBottomCalled) {
                        scrollToBottomCalled = true;
                        setTimeout(() => {
                            scrollToBottom();
                        }, 2000);
                    }
                }
                // else if (temmessage.status) {
                //     setMessages((prevMessages) => {
                //         const updatedMessages = prevMessages.map((message) => {
                //             return { ...message, read: true };
                //         });
                //         return updatedMessages;
                //     });
                // }
                else {
                    const messageData = temmessage;
                    if (
                        (messageData.content !== undefined || messageData.attachement_url !== undefined) &&
                        (messageData.topic_id === null || messageData.topic_id === 0) &&
                        (messageData.thread_id === null || messageData.thread_id === 0) &&
                        !messages.some((msg) => msg.message_uuid === messageData.message_uuid)
                    ) {
                        const newMessage = {
                            _id: messageData.id,
                            text: messageData.content,
                            userId: messageData.sender_id,
                            lastMessageTime: formatMessageTimeForChat(messageData.created_at),
                            type: messageData.sender_id === window.product_userId ? 'outgoing' : 'incoming',
                            read: messageData.isread,
                            fileUri: messageData.attachement_url,
                            fileType: messageData.attachment_type,
                            fileName: messageData.attachment_name,
                            userImage: data.profileimage,
                            message_uuid: messageData.message_uuid,
                            reply_message: messageData.reply_message,
                            reply_message_id: messageData.reply_message_id,
                            reply_message_type: messageData.reply_message_type,
                            reply_message_name: messageData.reply_message_name,
                            topic_id: messageData.topic_id,
                            thread_id: messageData.thread_id,
                            total_thread_count: messageData.total_thread_count,
                            thread_id_no: messageData.thread_id_no,
                            userName: messageData.first_name + (messageData.last_name ? " " + messageData.last_name : ""),
                        };

                        setMessages((prevMessages) => {
                            if (!prevMessages.some((msg) => msg.message_uuid === newMessage.message_uuid)) {

                                if (messageData.sender_id !== window.product_userId)
                                    readMessage(window.product_userId)
                                const newArray = Array.isArray(newMessage) ? newMessage : [newMessage];

                                // Prepend the new messages to the existing messages
                                const updatedMessages = [...newArray, ...prevMessages];

                                // Sort the messages by the created_at field in descending order
                                const sortedMessages = updatedMessages.sort((a, b) =>
                                    new Date(b.lastMessageTime).getTime() - new Date(a.lastMessageTime).getTime()
                                );
                                return sortedMessages;
                            }
                            return prevMessages;
                        });
                    }
                    setIsLoading(false);
                    setTimeout(() => {
                        scrollToBottom();
                    }, 2000);
                }
            });

        } catch (error) {
            setIsLoading(false);
        }
    };

    // Marks a message as read in the chat. This is called when someone visits the page
    const readMessage = async (userId) => {
        const getmessageobj = {
            type: "mark_read",
            receiver: data.id,
            //sender: userId,
            sender: parseInt(window.product_userId)
        }
        apiService.sendMessageListRequest(JSON.stringify(getmessageobj));
    };

    function scrollToBottom() {
        myRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    async function handleScroll(event) {
        const { scrollTop } = event.target;

        if (scrollTop === 0 && !isFetchingMore && messages.length > 0) {
            setIsFetchingMore(true);
            try {
                const lastMessageId = messages[messages.length - 1]._id;
                await getHistroy(window.product_userId, lastMessageId);
                event.target.scrollTop = 30
            } catch (error) {
                console.error('Error fetching more messages:', error);
            } finally {
                setIsFetchingMore(false);
            }
        }
    };

    const onSend = useCallback(async ({ url, contentType, filename }) => {
        if (showGroupChat) {
            setShowGroupChat(false)
        }

        if (showTopicDeatils) {
            setShowTopicDetails(false)
        }

        if (pastedImage && (!pastedImageRef.current || pastedImageRef.current !== pastedImage)) {
            pastedImageRef.current = pastedImage;
            uploadMediaToFirestore(pastedImage);
            setPastedImage(null);
            return;
        }

        if (textMsg.trim() || url) {
            const sendobj = {
                message_uuid: uuidv4(),
                sender: window.product_userId,
                receiver: data['group_name'] ? 0 : data.id,
                message: textMsg,
                attachment: url,
                attachment_type: contentType,
                attachment_name: filename,
                reply: null,
                agency_id: data['group_name'] ? null : window.agencyId,
                group_id: data['group_name'] ? data.id : null,
                topic_id: 0,
                thread_id: 0
            };

            apiService.sendMessageListRequest(JSON.stringify(sendobj));

            setTextMsg('');
            setFileUri('');
            setFileType('');
            setFileName('');
            scrollToBottom();
        } else {
            return;
        }
    }, [textMsg, messages, fileUri, fileType, fileName, pastedImage, showGroupChat, showTopicDeatils]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        uploadMediaToFirestore(file, "")
    };

    const handleFileClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleDownload = (fileUri) => {
        if (fileUri) {
            window.open(fileUri, '_blank');
        }
    };

    function onClose() {
        setShowTopicDetails(false)
        setShowTopics(false)
        setShowPeopleList(false)
        setShowGroupChat(false)
        setSelectedMessage(null)
    }

    const openPeopleList = () => {
        setShowTopicDetails(false)
        setShowPeopleList(true);
        setShowTopics(false);
        setShowGroupChat(false);
        setSelectedMessage(null);
    };

    const openTopicsList = () => {
        setShowTopics(true);
        setShowPeopleList(false);
        setShowGroupChat(false);
        setSelectedMessage(null);
    };


    const handleTextMessageClick = (message) => {
        if (data['group_name'] && data['group_name'] !== "general") {
            setSelectedMessage(message);
            setShowGroupChat(true);
            setShowTopics(false);
            setShowPeopleList(false);
        }
    };

    function onTopicDetailsClose() {
        setShowTopicDetails(false)
    }

    function onTopicDetailsOpen() {
        setShowTopicDetails(true)
    }

    const uploadMediaToFirestore = async (res) => {
        const fileSizeLimit = 5 * 1024 * 1024;
        if (res.size > fileSizeLimit) {
            toast.error("File size exceeds the limit (5 MB).")
            return;
        }

        const filename = `${res.name}_${Date.now()}`;

        const storage = getStorage(app);
        const fileRef = ref(storage, filename);

        const contentType = res.type;

        let tempfiletype;
        let tempfilename = res.name;
        if (res.type.includes("image")) {
            tempfiletype = "image";
        } else if (res.type.includes("audio")) {
            tempfiletype = "audio";
        } else {
            tempfiletype = "document";
        }

        // Fetch the media content with the appropriate content type.
        const response = await fetch(URL.createObjectURL(res), {
            headers: { "Content-Type": contentType },
        });
        const blob = await response.blob();
        const metadata = { contentType };

        uploadBytes(fileRef, blob, metadata)
            .then((uploadTask) => {
                getDownloadURL(uploadTask.ref)
                    .then(async (url) => {
                        toast.success("File uploaded!");
                        setFileUri(url);
                        setFileType(tempfiletype);
                        setFileName(tempfilename);
                        await onSend({ url, contentType: tempfiletype, filename: tempfilename });
                    })
                    .catch((error) => {
                        toast.error("error while downloading the file.");
                        console.error(error);
                    });
            })
            .catch((error) => {
                toast.error("error while uploading the file.");
                console.error(error);
            });
    };

    const handlePaste = (event) => {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items;
        let blob = null;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf("image") !== -1) {
                blob = items[i].getAsFile();
                break;
            }
        }
        if (blob !== null) {
            setPastedImage(blob);
        }
    };

    function handleKeyDown(e) {
        if (e.shiftKey && e.key === "Enter") {
            e.preventDefault();
            setTextMsg(textMsg + "\n");
        } else if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            onSend({ url: '', contentType: '', filename: '' });
        }
    };

    async function handleExitGroupClick() {
        try {
            const response = await apiService.delete("shopperpad/leave-group/", {
                group_id: data.id,
                member_id: window.product_userId,
            });

            if (response.status === "success") {
                setShowComponent('onLeaveGroup')
                refreshLeftNavigation()
            } else {
                console.error('Failed to leave group');
            }
        } catch (error) {
            console.error('Error leaving group:', error);
        }
    }

    async function handleDeleteGroupClick() {
        try {
            const response = await apiService.delete("shopperpad/delete-group/", {
                group_id: data.id,
                subscriber_id: window.agencyId,
                user_id: window.product_userId,
            });

            if (response.status === "success") {
                setShowComponent('onDeleteGroup')
                refreshLeftNavigation()
            } else {
                console.error('Failed to leave group');
            }
        } catch (error) {
            console.error('Error leaving group:', error);
        }
    }

    if (showComponent === 'onLeaveGroup' || showComponent === 'onDeleteGroup') {
        return <HomeScreen />
    }

    return (
        <div className="w-[1080px] flex flex-row items-center justify-start chatMain">
            <section
                className="flex-1 bg-white h-[1024px] flex flex-col items-center justify-center min-w-[360px] text-left text-lg text-headings font-base-md-regular"
                id="content_pan"
            >
                <div
                    className="self-stretch bg-app-background h-20 flex flex-row items-center justify-start py-0 px-4 box-border gap-[16px]"
                    id="content_pan_header"
                >
                    <button className="cursor-pointer [border:none] p-2 bg-[transparent] flex flex-row items-center justify-center">
                        {/* <img
                    className="w-[25px] relative h-[25px] overflow-hidden shrink-0 object-cover"
                    alt=""
                    src={icon_back} /> */}
                    </button>
                    <div className="flex-1 flex flex-row items-center justify-start">
                        <b className="relative tracking-[0.01em] leading-[140%]">
                            {data['group_name'] ? '#' + data.group_name : data.firstname}
                        </b>
                    </div>
                    {
                        data['group_name'] && data['group_name'] !== "general" && (
                            <>
                                <div class="tooltip-container">
                                    <button className="cursor-pointer [border:none] p-2 bg-[transparent] rounded-[4.17px] flex flex-row items-center justify-center" onClick={openTopicsList}>
                                        <img
                                            className="w-6 relative h-6 overflow-hidden shrink-0 object-cover"
                                            alt=""
                                            src={icon_topics}
                                        />
                                    </button>
                                    <span class="tooltip-text">Topics List</span>
                                </div>
                                <div class="tooltip-container">
                                    <button className="cursor-pointer [border:none] p-2 bg-[transparent] rounded-[4.17px] flex flex-row items-center justify-center" onClick={openPeopleList}>
                                        <img
                                            className="w-6 relative h-6 overflow-hidden shrink-0 object-cover"
                                            alt=""
                                            src={icon_people}
                                        />
                                    </button>
                                    <span class="tooltip-text">People List</span>
                                </div>
                                {data.group_adminId === window.product_userId && (
                                    <div class="tooltip-container">
                                        <button className="cursor-pointer [border:none] p-2 bg-[transparent] rounded-[4.17px] flex flex-row items-center justify-center" onClick={handleDeleteGroupClick} >
                                            <img
                                                className="w-6 relative h-6 overflow-hidden shrink-0 object-cover"
                                                alt=""
                                                src={trash}
                                            />
                                        </button>
                                        <span class="tooltip-text">Delete Group</span>
                                    </div>
                                )}
                                {data.group_adminId !== window.product_userId && (
                                    <div class="tooltip-container">
                                        <button className="cursor-pointer [border:none] p-2 bg-[transparent] rounded-[4.17px] flex flex-row items-center justify-center" onClick={handleExitGroupClick} >
                                            <img
                                                className="w-6 relative h-6 overflow-hidden shrink-0 object-cover"
                                                alt=""
                                                src={exit}
                                            />
                                        </button>
                                        <span class="tooltip-text">Leave Group</span>
                                    </div>

                                )}
                                {/* <button className="cursor-pointer [border:none] p-2 bg-[transparent] rounded-[4.17px] flex flex-row items-center justify-center">
                <img
                    className="w-6 relative h-6 overflow-hidden shrink-0 object-cover"
                    alt=""
                    src={icon_audio_call} />
            </button>
            <button className="cursor-pointer [border:none] p-2 bg-[transparent] rounded-[4.17px] flex flex-row items-center justify-center">
                <img
                    className="w-6 relative h-6 overflow-hidden shrink-0 object-cover"
                    alt=""
                    src={icon_video_call} />
            </button>
            <button className="cursor-pointer [border:none] p-2 bg-[transparent] rounded-[4.17px] flex flex-row items-center justify-center">
                <img
                    className="w-6 relative h-6 overflow-hidden shrink-0 object-cover"
                    alt=""
                    src={icon_kebab_menu} />
            </button> */}
                            </>
                        )
                    }

                </div>
                <div
                    className="self-stretch flex-1 flex flex-col items-start justify-start p-6 text-xs"
                    id="chat_area"
                    key={data.id}
                    onScroll={handleScroll}
                >
                    <div className="self-stretch flex-1 flex flex-col items-center justify-start" key={data.id}>
                        <div className="self-stretch flex flex-col items-start justify-center py-2.5 px-4 gap-[8px]">
                            {/* <div className="self-stretch rounded bg-stroke-light flex flex-col items-start justify-center py-[7.5px] px-3">
                            <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
                                <div className="relative tracking-[0.01em] leading-[140%] font-semibold">
                                    Last Name, First Name
                                </div>
                                <div className="relative text-[10px] leading-[120%]">
                                    12:37pm
                                </div>
                            </div>
                            <div className="self-stretch flex flex-row items-start justify-start text-sm text-paragraph">
                                <div className="flex-1 flex flex-col items-center justify-start">
                                    <div className="self-stretch flex flex-row items-center justify-start py-[3px] px-0">
                                        <div className="flex-1 relative leading-[140%]">
                                            Want to join me at Fatehsagar?
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                            {messages ? (
                                messages.slice().reverse().map((item) => (
                                    <div className="self-stretch flex flex-row items-start justify-start text-base gap-[8px]" id="inner-chat-blg" key={item.id}>
                                        <div className="w-6 rounded-45xl bg-stroke-light h-6 flex flex-row items-center justify-center p-1.5 box-border relative gap-[8px]">
                                            <img
                                                className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover z-[0]"
                                                alt=""
                                                src={user_image} />
                                            {/* <img
                                            className="w-3 absolute !m-[0] right-[0px] bottom-[0px] rounded-[50%] h-3 object-cover z-[1]"
                                            alt=""
                                            src={user_online} /> */}
                                        </div>
                                        <div className="message-design-bg flex-1 flex flex-col items-center justify-start">
                                            <div className="self-stretch flex flex-row items-center justify-start py-0 px-2 gap-[8px]">
                                                <div className="relative tracking-[0.01em] leading-[140%] font-semibold">
                                                    {item.userName}
                                                </div>
                                                <div className="relative text-xs leading-[120%]">
                                                    {item.lastMessageTime}
                                                </div>
                                            </div>
                                            <div className="self-stretch flex flex-col items-start justify-center py-1 px-2 gap-[4px] text-paragraph">
                                                <div className="self-stretch relative leading-[140%]">
                                                    <TextWithLink inputText={item.text} isReply={false} onClick={() => handleTextMessageClick(item)} />
                                                    {item.fileType === 'image' && (
                                                        <div onClick={() => handleTextMessageClick(item)}>
                                                            <a href={item.fileUri} target="_blank" rel="noopener noreferrer">
                                                                <img src={item.fileUri} alt="Attached Image" className="w-40 h-40 object-cover custom-border-img" />
                                                            </a>
                                                        </div>
                                                    )}
                                                    {item.fileType !== 'image' && item.fileUri && (
                                                        <div className="flex items-center justify-start" onClick={() => handleTextMessageClick(item)}>
                                                            <button onClick={() => handleDownload(item.fileUri)} className="flex items-center bg-gray-200 hover:bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded-md">
                                                                <span className="truncate">{item.fileName}</span>
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                                {item.total_thread_count > 0 &&
                                                    <div className="self-stretch flex flex-row items-start justify-start gap-[8px]">
                                                        {/* <button className="cursor-pointer py-1 px-2 bg-white rounded flex flex-row items-center justify-center gap-[4px] border-[1px] border-solid border-stroke-light">
                                                        <img
                                                            className="w-3.5 relative h-3.5 object-cover"
                                                            alt=""
                                                            src={icon_emoji_heart} />
                                                        <b className="relative text-xs leading-[140%] font-base-md-regular text-secondary-button-link text-left">
                                                            1
                                                        </b>
                                                    </button>
                                                    <button className="cursor-pointer py-1 px-2 bg-white rounded flex flex-row items-center justify-center gap-[4px] border-[1px] border-solid border-stroke-light">
                                                        <img
                                                            className="w-3.5 relative h-3.5 object-cover"
                                                            alt=""
                                                            src={icon_emoji_thumbsup} />
                                                        <b className="relative text-xs leading-[140%] font-base-md-regular text-secondary-button-link text-left">
                                                            1
                                                        </b>
                                                    </button>*/}
                                                        <button className="cursor-pointer py-1 px-2 bg-white rounded flex flex-row items-center justify-center gap-[4px] border-[1px] border-solid border-stroke-light" onClick={() => handleTextMessageClick(item)}>
                                                            <b className="relative text-xs leading-[140%] font-base-md-regular text-secondary-button-link text-left">
                                                                {item.total_thread_count} replies
                                                            </b>
                                                            <img
                                                                className="w-4 relative h-4 overflow-hidden shrink-0 object-cover"
                                                                alt=""
                                                                src={icon_thread} />
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>Loading...</p>
                            )}
                            <div ref={myRef}></div>
                        </div>
                    </div>
                </div>
                <div
                    className="self-stretch flex flex-col items-start justify-start p-4"
                    id="content_pan_footer"
                >
                    <div className="self-stretch rounded overflow-hidden flex flex-col items-start justify-start border-[1px] border-solid border-stroke-light">
                        <textarea
                            className="[border:none] [outline:none] font-base-md-regular text-16 bg-white self-stretch h-12 flex flex-row items-center justify-start py-2 px-4 box-border text-placeholder-text"
                            placeholder="Type your message"
                            type="text"
                            value={textMsg}
                            onChange={(e) => setTextMsg(e.target.value)}
                            onPaste={handlePaste}
                            onKeyDown={handleKeyDown}
                        />
                        {pastedImage && (
                            <>
                                <img src={URL.createObjectURL(pastedImage)} alt="pasted Image" className="w-40 h-40 object-cover" />
                                <button onClick={() => setPastedImage(null)}>
                                    <img
                                        className="w-6 relative h-6 overflow-hidden shrink-0"
                                        alt=""
                                        src={Closeicon}
                                    />
                                </button>
                            </>
                        )}
                        <div
                            className="self-stretch bg-stroke-light h-12 flex flex-row items-center justify-between py-0 px-4 box-border"
                            id="action_bar"
                        >
                            <div className="flex flex-row items-center justify-start gap-[8px]">
                                {/* <button className="cursor-pointer [border:none] p-0 bg-[transparent] w-6 h-6 flex flex-col items-center justify-center">
                                <img
                                    className="w-[9.6px] relative h-[13px] object-cover"
                                    alt=""
                                    src={icon_bold} />
                            </button>
                            <button className="cursor-pointer [border:none] p-0 bg-[transparent] w-6 h-6 flex flex-col items-center justify-center">
                                <img
                                    className="w-[7.3px] relative h-[14.2px] object-cover"
                                    alt=""
                                    src={icon_prompt} />
                            </button>
                            <button className="cursor-pointer [border:none] p-0 bg-[transparent] w-6 h-6 flex flex-col items-center justify-center">
                                <img
                                    className="w-[9.7px] relative h-[13.3px] object-cover"
                                    alt=""
                                    src={icon_strike} />
                            </button>
                            <button className="cursor-pointer [border:none] p-0 bg-[transparent] flex flex-col items-center justify-center">
                                <img
                                    className="w-6 relative h-6 overflow-hidden shrink-0 object-cover"
                                    alt=""
                                    src={icon_link} />
                            </button> */}
                            </div>
                            <div className="flex flex-row items-center justify-start gap-[8px]">
                                {/* <button className="cursor-pointer [border:none] p-0 bg-[transparent] h-6 flex flex-col items-center justify-center">
                                <img
                                    className="w-6 relative h-6 object-cover"
                                    alt=""
                                    src={icon_emoji} />
                            </button>*/}
                                <button className="cursor-pointer [border:none] p-0 bg-[transparent] flex flex-col items-center justify-center">
                                    <img
                                        className="w-6 relative h-6 overflow-hidden shrink-0 object-cover"
                                        alt=""
                                        src={icon_attachement}
                                        onClick={handleFileClick} />
                                    <input id="fileInput" type="file" onChange={(e) => handleFileChange(e)} hidden={true} />
                                </button>
                                <button className={`cursor-pointer [border:none] py-2 px-6 bg-primary-button-link rounded h-8 flex flex-row items-center justify-center box-border ${(!textMsg || textMsg.trim() === '') && !pastedImage ? 'disabled' : ''}`} onClick={(messages) => onSend(messages)} type="submit">
                                    <b className="relative text-xs leading-[120%] uppercase font-base-md-regular text-app-background text-center">
                                        Send
                                    </b>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {showTopics && <GroupChatTopicList onClose={onClose} groupId={groupId} onTopicDetailsClose={onTopicDetailsClose} showTopicDeatils={showTopicDeatils} onTopicDetailsOpen={onTopicDetailsOpen} />}
            {showPeopleList && <GroupChatPeople onClose={onClose} groupId={groupId} />}
            {showGroupChat && selectedMessage && (
                <GroupChatThread
                    selectedMessage={selectedMessage}
                    user_image={user_image}
                    onClose={onClose}
                    groupId={groupId}
                />
            )}
        </div>
    );
};

export default ChatScreen;