import agency_logo1 from "../assets/agency_logo1@2x.png";
import user_image from "../assets/user_image@2x.png";
import user_online from "../assets/user_online@2x.png";
import user_offline from "../assets/user_offlne@2x.png";
import { useState } from "react";

const LeftSidebar = (props) => {
    const { boardGroups, groups, users, sortedUsers, invitations, onChatComponent, onLogOutClick, onAgencyClick, onSetComponent, selectedItemId } = props
    const [searchQuery, setSearchQuery] = useState('');

    function handleSearch(event) {
        setSearchQuery(event.target.value);
    };

    const filteredUsers = users.filter((user) => {
        const fullName = `${user.lastname ? user.lastname + ', ' : ''}${user.firstname}`;
        return fullName.toLowerCase().includes(searchQuery.toLowerCase());
    });

    function renderBoardGroups() {
        if (!boardGroups) return <p>Loading...</p>;

        return boardGroups.map((item) => (
            <ul
                key={item.group_name}
                className={`m-0 self-stretch flex flex-row items-center justify-start py-3 px-4 pointercursor ${selectedItemId === item.id ? 'bg-stroke-light rounded-45xl' : ""}`}
                onClick={() => onChatComponent(item)}
            >
                <div className="flex-1 relative leading-[120%] font-semibold">
                    #{item.group_name}
                </div>
            </ul>
        ));
    };

    function renderGroups() {
        if (!groups) return <p>Loading...</p>;

        return groups.map((item) => (
            <ul
                key={item.group_name}
                className={`m-0 self-stretch flex flex-row items-center justify-start py-2 px-4 gap-[8px] text-headings pointercursor ${selectedItemId === item.id ? 'bg-stroke-light rounded-45xl' : ""}`}
                onClick={() => onChatComponent(item)}
            >
                <div className="flex-1 relative leading-[120%] font-semibold">
                    #{item.group_name}
                </div>
                {item.unread > 0 && (
                    <span className="rounded bg-primary-bright flex flex-row items-center justify-center py-0.5 px-1.5 text-xs text-app-background">
                        <div className="relative leading-[120%] font-semibold">
                            {item.unread}
                        </div>
                    </span>
                )}
            </ul>
        ));
    };

    function renderUsers() {
        if (!filteredUsers.length) return <p>no people found</p>;

        return filteredUsers.map((item) => (
            <div
                key={item.id}
                className={`self-stretch flex flex-row items-center justify-start py-2.5 px-4 pointercursor ${selectedItemId === item.id ? 'bg-stroke-light rounded-45xl' : ""}`}
                onClick={() => onChatComponent(item)}
            >
                <div className="flex-1 flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-6 rounded-45xl bg-stroke-light h-6 flex flex-row items-center justify-center p-1.5 box-border relative gap-[8px]">
                        <img
                            className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover z-[0]"
                            alt=""
                            src={user_image}
                        />
                        <img
                            className="w-3 absolute !m-[0] right-[0px] bottom-[0px] rounded-[50%] h-3 object-cover z-[1]"
                            alt=""
                            src={item.isonline ? user_online : user_offline}
                        />
                    </div>
                    <div className="flex-1 flex flex-row items-end justify-start">
                        <div className="relative tracking-[0.01em] leading-[140%] font-semibold">
                            {item.lastname ? `${item.lastname}, ${item.firstname}` : item.firstname}
                        </div>
                        {item.unread > 0 && (
                            <span className="rounded bg-primary-bright flex flex-row items-center justify-center py-0.5 px-1.5 text-xs text-app-background">
                                <div className="relative leading-[120%] font-semibold">{item.unread}</div>
                            </span>
                        )}
                    </div>
                </div>
            </div>
        ));
    };

    function renderInvitations() {
        if (!invitations.length) return null;

        return invitations.map((item) => (
            <div
                key={item.id}
                className="self-stretch flex flex-row items-center justify-start py-2.5 px-4 gap-[16px] opacity-[0.4]"
            >
                <div className="flex-1 flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-6 rounded-45xl bg-stroke-light h-6 flex flex-row items-center justify-center p-1.5 box-border">
                        <img
                            className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
                            alt=""
                            src={user_image}
                        />
                    </div>
                    <div className="flex-1 flex flex-row items-end justify-start">
                        <div className="relative tracking-[0.01em] leading-[140%] font-semibold">
                            {item.mobile_number}
                        </div>
                    </div>
                </div>
                <div className="relative text-sm tracking-[0.02em] leading-[140%] text-stroke">Invited</div>
            </div>
        ));
    };

    return (
        <section
            className="w-[360px] bg-app-background h-[1024px] flex flex-col items-start justify-start min-w-[360px] text-left text-base text-headings font-open-sans"
            id="left_sidebar"
        >
            {/* Sidebar header */}
            <div className="self-stretch flex flex-row items-center justify-start p-4 gap-[8px] border-[1px] border-solid border-slategray-200 pointercursor" id="sidebar_header" onClick={onAgencyClick}>
                <button className="cursor-pointer [border:none] p-0 bg-stroke-light w-10 rounded h-10 flex flex-col items-center justify-center">
                    <img className="w-[24.2px] relative h-6 object-cover" alt="" src={agency_logo1} />
                </button>
                <div className="flex-1 relative leading-[120%] font-semibold">{window.agencyName}</div>
            </div>
            <div className="left-sidebar-content overflow-auto" style={{ maxHeight: 'calc(100% - 150px)', width: '100%' }}>
                {/* Board groups */}
                <div className="self-stretch flex flex-col items-start justify-start pt-4 px-4 pb-2 gap-[8px] border-[1px] border-solid border-slategray-200" id="block_board">
                    <button className="cursor-pointer [border:none] p-0 bg-[transparent] self-stretch h-5 flex flex-row items-center justify-start gap-[8px]">
                        <b className="flex-1 relative text-xs leading-[120%] uppercase font-base-md-regular text-placeholder-text text-left">Boards</b>
                    </button>
                    <div className="self-stretch flex flex-col items-start justify-start">
                        {renderBoardGroups()}
                    </div>
                </div>
                {/* Groups */}
                <div className="self-stretch flex flex-col items-start justify-start pt-4 px-4 pb-2 gap-[8px] text-secondary-button-link border-[1px] border-solid border-slategray-200" id="block_group">
                    <button className="cursor-pointer [border:none] p-0 bg-[transparent] self-stretch h-5 flex flex-row items-center justify-start gap-[8px]">
                        <b className="flex-1 relative text-xs leading-[120%] uppercase font-base-md-regular text-placeholder-text text-left">Groups</b>
                    </button>
                    {window.roleId === 1 && (
                        <a className="[text-decoration:none] self-stretch relative tracking-[0.01em] leading-[140%] font-bold text-[inherit]" href="#" onClick={() => onSetComponent('CreateGroup')}>
                            + Add Group
                        </a>
                    )}
                    <div className="self-stretch flex flex-col items-start justify-start text-placeholder-text">
                        {renderGroups()}
                    </div>
                </div>
                {/* people */}
                {users.length > 0 && (
                    <div className="self-stretch flex flex-col items-start justify-start pt-4 px-4 pb-2 gap-[8px]" id="block_people">
                        <button className="cursor-pointer [border:none] p-0 bg-[transparent] self-stretch flex flex-row items-center justify-start gap-[8px]">
                            <b className="relative text-xs leading-[120%] uppercase font-base-md-regular text-placeholder-text text-left">People</b>
                            {/* Search bar */}
                            <div className="self-stretch flex flex-col items-start justify-start px-4 pb-2 gap-[8px] " id="block_people">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <input
                                        type="text"
                                        placeholder="Search people"
                                        value={searchQuery}
                                        onChange={handleSearch}
                                        className="border-[1px] border-solid border-slategray-200 rounded p-3 min-w-full rounded-45xl"
                                    />
                                </div>
                            </div>
                        </button>
                        <div className="self-stretch flex flex-col items-start justify-start people-listsidebar-dv">
                            {renderUsers()}
                        </div>
                    </div>
                )}
                {/* Invited */}
                {window.roleId === 1 && (
                    <div className="self-stretch flex flex-col items-start justify-start pt-4 px-4 pb-2 gap-[8px] text-secondary-button-link" id="block_invited">
                        <button className="cursor-pointer [border:none] p-0 bg-[transparent] self-stretch h-5 flex flex-row items-center justify-start gap-[8px]">
                            <b className="flex-1 relative text-xs leading-[120%] uppercase font-base-md-regular text-placeholder-text text-left">Invited</b>
                        </button>
                        <a className="[text-decoration:none] self-stretch relative tracking-[0.01em] leading-[140%] font-bold text-[inherit]" href="#" onClick={() => onSetComponent('InvitePeople')}>
                            + Invite People
                        </a>
                        <div className="self-stretch flex flex-col items-start justify-start text-headings">
                            {renderInvitations()}
                        </div>
                    </div>
                )}
            </div>
            {/* Logout */}
            <div className="self-stretch flex flex-row items-center justify-start p-4 gap-[8px] border-[1px] border-solid border-slategray-200 pointercursor" id="sidebar_header" onClick={onLogOutClick}>
                <div className="flex-1 relative leading-[120%] font-semibold">Log Out</div>
            </div>
        </section>
    );
}

export default LeftSidebar